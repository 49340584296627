<template>
  <div class="content-div">
    <h1>Video Demo</h1>
    <p>Please click the video below to view the Online Help video for illustration of the steps for checking information at the Online Licence Services.</p>
    <v-responsive :aspect-ratio="16/9">
          <iframe id="video-frame" src="https://www.youtube.com/embed/x46dO7qIxoQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </v-responsive>
    <h1 class="mt-n15">PDF Demo</h1>
    <p>Click to view the pdf demo</p>
    <div class="d-flex align-center flex-wrap t-wrap">
      <img class="mb-4 mt-2" src="../../assets/pdf.svg" />
      <h5 class="fw-400 em-18 ml-3 mb-3 "><a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">Application for new issue of a liquor licence</a></h5>
    </div>
    <div class="d-flex align-center flex-wrap t-wrap">
      <img class="mb-4 mt-2" src="../../assets/pdf.svg" />
      <h5 class="fw-400 em-18 ml-3 mb-3 "><a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">Application for renewal of a liquor licence</a></h5>
    </div>
    <div class="d-flex align-center flex-wrap t-wrap">
      <img class="mb-4 mt-2" src="../../assets/pdf.svg" />
      <h5 class="fw-400 em-18 ml-3 mb-3 "><a :href="publicPath+'fehb106_new form_filled.pdf'" target="_blank">Application for transfer of a liquor licence</a></h5>
    </div>
  </div>
  
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InDemo',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
      publicPath: process.env.BASE_URL
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

#video-frame{
  max-width:850px;
  max-height:480px;
  width:100%;
  height:100%;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

.content-div h3{
  font-size:2.125em;
}

ol,.expansion ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}

h5 a {
  text-decoration: underline !important;
}

img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
  

}

</style>